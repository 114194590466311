<template>
  <div>
    <b-card>
      <b-button v-if="!loading" :disabled="running" @click="deleteAllSelections">Delete All Selections</b-button>
      <div>
        Selections: {{ selections.items.length }} | Applications: {{ applications.items.length }}
      </div>
    </b-card>

  </div>
</template>

<script>
import {API, graphqlOperation} from 'aws-amplify';
import {deleteSelection, listApplications, listSelections, updateApplication} from './selections';

export default {
  name: 'Tools',
  data() {
    return {
      loading: false,
      running: false,
      applications: {
        items: [],
        loading: true
      },
      selections: {
        items: [],
        loading: true
      }
    }
  },
  async mounted() {
    this.loading = true
    await this.listSelections()
    await this.listApplications()
    this.loading = false
  },
  methods: {
    async listSelections(nextToken, pagedSelections) {
      const selections = pagedSelections || []
      const input = { limit: 500, nextToken: nextToken }

      const response = await API.graphql(graphqlOperation(listSelections, input));
      selections.push(...response.data.listSelections.items)

      if(response.data.listSelections.nextToken) {
        await this.listSelections(response.data.listSelections.nextToken, selections)
      }
      else {
        this.selections.items = selections
        this.selections.loading = false
      }
    },
    async listApplications(nextToken, pagedApplications) {
      const applications = pagedApplications || []
      const input = { limit: 500, nextToken: nextToken, filter: {applicationSelectionId: {attributeExists: true}} }

      const response = await API.graphql(graphqlOperation(listApplications, input));
      applications.push(...response.data.listApplications.items)

      if(response.data.listApplications.nextToken) {
        await this.listApplications(response.data.listApplications.nextToken, applications)
      }
      else {
        this.applications.items = applications
        this.applications.loading = false
      }
    },

    async deleteAllSelections() {
      this.running = true
      await this.selections.items.reduce(async (referencePoint, selection, index) => {
        try {
          await referencePoint;
          await API.graphql(graphqlOperation(deleteSelection, { input: { id: selection.id } } ));
        }
        catch (e) {
          console.error(e)
        }
      }, Promise.resolve());

      await this.applications.items.reduce(async (referencePoint, application, index) => {
        await referencePoint;
        await API.graphql(graphqlOperation(updateApplication, { input: { id: application.id, applicationSelectionId: null } } )); //detach selection to application
      }, Promise.resolve());

      await this.listSelections()
      await this.listApplications()
      this.running = false
    }
  }
}
</script>

<style scoped>

</style>
