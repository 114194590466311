export const listSelections = /* GraphQL */ `
    query ListSelections(
        $id: ID
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
            }
            nextToken
        }
    }
`;

export const deleteSelection = /* GraphQL */ `
    mutation DeleteSelection(
        $input: DeleteSelectionInput!
        $condition: ModelSelectionConditionInput
    ) {
        deleteSelection(input: $input, condition: $condition) {
            id
        }
    }
`;

export const listApplications = /* GraphQL */ `
    query ListApplications(
        $id: ID
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listApplications(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                applicationSelectionId
            }
            nextToken
        }
    }
`;

export const updateApplication = /* GraphQL */ `
    mutation UpdateApplication(
        $input: UpdateApplicationInput!
        $condition: ModelApplicationConditionInput
    ) {
        updateApplication(input: $input, condition: $condition) {
            id
            applicationSelectionId
        }
    }
`;
